import React, { Component } from 'react';
import {
    Col, Row, Card, CardBody, Container, FormGroup, Label, Input, Button, CustomInput,
    Nav, NavItem, NavLink, TabPane, TabContent, Table
} from 'reactstrap';
import './AuctionsAddEdit.scss';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Form Editor
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import axios from 'axios';
import { connect } from 'react-redux';
import { Constants } from '../../Constants';
import { auctionAdd, auctionUpdate, auctionGet, sendPdfs, auctionGetMoneys, getItemsType, downloadPdfs, getConfigs, postLogsByAuction } from '../../services/AuctionServices';

//Dropzone
import Dropzone from "react-dropzone";
import { addLotsMassive, getLots, add, edit, deleteLot } from '../../services/LotServices';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import PaginationCustom from '../../components/pagination/Pagination';

// Modals
import ModalForceWin from '../../components/ModalForceWin';
import ModalShowImage from '../../components/ModalShowImage/ModalShowImage';
import ModalCreateLot from '../../components/ModalCreateLot/ModalCreateLot';
import ModalCreateLots from '../../components/ModalCreateLots/ModalCreateLots';
import ModalCropImage from '../../components/ModalCropImage/ModalCropImage';
import { customersListAutocomplete } from '../../services/UserServices';

class AuctionsAddEdit extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remates", link: "/remates" },
            { title: `${this.props.match.params.id === '0' ? 'Crear remate' : 'Editar remate'}`, link: "#" }
        ],
        activeTab: 1,
        auction: {
            id: parseInt(this.props.match.params.id),
            title: '',
            description: EditorState.createEmpty(),
            dateTimeFrom: '',
            dateTimeTo: '',
            image: '',
            image_thumb: '',
            image_name: '',
            image_size: '',
            catalog: '',
            visible: { value: 0, label: 'No visible' },
            finished: false,
            money: null,
            logs: [],
            auctionType: null,
            moneySymbol: '',
            totalIngress: 0,
            iva: 0,
            subtotal: 0,
            comision: 0,
            info_aditional: EditorState.createEmpty(),
            lastIdLot: '',
            dateTimeVisibility: '',
        },
        loading: false,
        moneys: [],
        items: [],
        lotSelect: null,
        lots: [],
        page: 1,
        pages: 0,
        searchLot: '',
        imageShow: null,
        imageShowIdItem: null,
        filterStatus: { value: 'all', label: 'Filtrar por estado' },
        showAddLotModal: false,
        lotsFromExcel: null,
        showModalCreateLots: false,
        itemToUpload: null,
        modalCropImage: [],
        customers: [],
    }

    componentDidMount = async () => {
        toast.configure();
        await this.fetchMoneys();
        await this.fetchItems();
        await this.fetchConfigs();
        if (this.state.auction.id !== 0) {
            await this.fetchAuction(this.state.auction.id);
            await this.fetchLots();
        }
        this.fetchCustomers();
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                customers: response.data.data.customers
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchConfigs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getConfigs({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                loading: false,
                auction: {
                    ...this.state.auction,
                    description: EditorState.createWithContent(stateFromHTML(response.data.data.config.default_description)),
                    info_aditional: EditorState.createWithContent(stateFromHTML(response.data.data.config.info_aditional)),
                }
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchMoneys = async () => {
        await this.setState({ loading: true });
        try {
            let response = await auctionGetMoneys({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                moneys: response.data.data,
                auction: {
                    ...this.state.auction,
                    money: response.data.data.length > 0 ? response.data.data[0] : null,
                },
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchLots = async () => {
        await this.setState({ loading: true });
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                auction: this.state.auction.id,
                offset: 50,
            };
            if (this.state.filterStatus.value !== 'all') {
                data.filterStatus = this.state.filterStatus.value;
            }
            let response = await getLots(data);
            let lots = response.data.data.lots;
            lots.map(item => {
                item.showButtonSaveTitle = false;
                item.showButtonSavePriceBase = false;
                item.showButtonSaveDescription = false;
                item.showButtonSaveQuantity = false;
            });
            await this.setState({
                pages: response.data.data.pages,
                lots: lots,
                auction: {
                    ...this.state.auction,
                    totalIngress: response.data.totalIngress,
                    iva: response.data.iva,
                    subtotal: response.data.subTotal,
                    comision: response.data.comision,
                    lastIdLot: response.data.lastIdLot
                },
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchItems = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getItemsType({
                token: this.props.sessionProps.account.token
            });
            let auctionType = null;
            if (response.data.data.items.length > 0) {
                auctionType = response.data.data.items[0];
                auctionType = { value: auctionType.id, label: auctionType.description };
            }
            await this.setState({
                items: response.data.data.items.map(item => ({ value: item.id, label: item.description })),
                auction: {
                    ...this.state.auction,
                    auctionType: auctionType
                },
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchAuction = async (id, showSpinner = true) => {
        showSpinner && await this.setState({ loading: true });
        try {
            let response = await auctionGet({
                token: this.props.sessionProps.account.token,
                id: id
            });
            let { title, description, date_from, date_to, image, image_thumb, visible, finished, money, moneySymbol, auction_type,
                tax_municipal, info_aditional, catalog, date_visibility } = response.data.data;

            let auctionType = this.state.items.filter(item => item.value === auction_type);
            let items = this.state.items;
            if (auctionType.length > 0) {
                auctionType = auctionType[0];
                items = this.state.items.filter(item => item.online === auctionType.online);
            }
            await this.setState({
                auction: {
                    ...this.state.auction,
                    id: id,
                    title: title,
                    description: description !== "<p><br></p>" ? EditorState.createWithContent(stateFromHTML(description)) : this.state.auction.description,
                    dateTimeFrom: moment(date_from.replace(' ', 'T')),
                    dateTimeTo: date_to !== null && moment(date_to.replace(' ', 'T')),
                    dateTimeVisibility: date_visibility !== null && moment(date_visibility.replace(' ', 'T')),
                    image: image,
                    image_thumb: image_thumb,
                    image_name: `Imagen ${title}`,
                    image_size: `${Math.floor(Math.random() * (300 - 50 + 1)) + 50}`,
                    finished: finished,
                    visible: { value: visible, label: visible == 0 ? 'No visible' : 'Visible' },
                    money: { value: money, label: moneySymbol },
                    moneySymbol: moneySymbol,
                    auctionType: auctionType ? auctionType : null,
                    info_aditional: info_aditional !== "<p><br></p>" ? EditorState.createWithContent(stateFromHTML(info_aditional)) : this.state.auction.info_aditional,
                    catalog: catalog
                },
                loading: false,
                items: items,
                selectTaxMunicipal: tax_municipal === null ? { value: 0, label: 'No' } : { value: 1, label: 'Si' }
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchLogs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await postLogsByAuction({
                token: this.props.sessionProps.account.token,
                auction: this.state.auction.id
            });
            await this.setState({
                auction: {
                    ...this.state.auction,
                    logs: response.data.data.logs,
                },
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    handleSelectChange = async (selectedOption, name, auction = true) => {
        if (auction) {
            await this.setState({
                auction: {
                    ...this.state.auction,
                    [name]: selectedOption
                }
            });
        }
        else {
            await this.setState({
                [name]: selectedOption
            });
        }
    };

    handleFileChange = async (event) => {
        for (let file of event.target.files) {
            var form = new FormData();
            form.append('file', file);
            try {
                const result = await axios.post(`${Constants.BASE_URL}auctions/image_upload`, form, {
                    headers: {
                        'Authorization': this.props.sessionProps.account.token,
                        'content-type': 'multipart/form-data'
                    }
                });
                await this.setState({
                    auction: {
                        ...this.state.auction,
                        image: result.data.data.image,
                        image_thumb: result.data.data.image_thumb,
                        image_name: file.name,
                        image_size: (file.size / 1024).toFixed(2)
                    }
                });
            } catch (error) {
                if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleCatalogChange = async (event) => {
        for (let file of event.target.files) {
            await this.setState({ loading: true });
            var form = new FormData();
            form.append('file', file);
            try {
                const result = await axios.post(`${Constants.BASE_URL}auctions/image_upload?type=catalogs`, form, {
                    headers: {
                        'Authorization': this.props.sessionProps.account.token,
                        'content-type': 'multipart/form-data'
                    }
                });
                await this.setState({
                    auction: {
                        ...this.state.auction,
                        catalog: result.data.data.image
                    },
                    loading: false
                });
            } catch (error) {
                await this.setState({ loading: false });
                if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeModalCrop = async (index) => {
        let modalCropImages = this.state.modalCropImage;
        modalCropImages[index].show = false;
        await this.setState({
            modalCropImage: modalCropImages
        });
    }

    handleFileChangeLot = async (event, item) => {
        let eventTargetFiles = event.target.files;
        await this.setState({ loading: true, itemToUpload: item });
        let files = [];
        for (let file of eventTargetFiles) {
            files.push({
                file: await this.readFile(file),
                show: true
            });
        }
        await this.setState({
            modalCropImage: files,
            loading: false
        })
    }

    readFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    uploadImage = async (file, index) => {
        file.name = 'image_' + Date.now() + '.jpeg';
        var form = new FormData();
        form.append('file', file);
        try {
            await this.setState({ loading: true });
            const result = await axios.post(`${Constants.BASE_URL_IMAGES}image_upload?type=lots&owner=${Constants.API_NAME}`, form, {
                headers: {
                    'Authorization': this.props.sessionProps.account.token,
                    'content-type': 'multipart/form-data'
                }
            });
            this.closeModalCrop(index);
            let images = [
                ...this.state.itemToUpload.images,
                {
                    id_lot: this.state.itemToUpload?.id,
                    image: Constants.BASE_URL_IMAGES + result.data.data.image,
                    image_thumb: Constants.BASE_URL_IMAGES + result.data.data.image_thumb
                }];
            await this.saveLot(this.state.itemToUpload, null, null, null, null, images);
            await this.setState({ loading: false });
            this.fetchLots();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    deleteImage = async (image) => {
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id === this.state.imageShowIdItem) {
                let imagesShow = [];
                for (let _image of lot.images) {
                    if (_image !== image) {
                        imagesShow.push(_image);
                    }
                }
                this.saveLot(lot, null, null, null, null, imagesShow);
                this.closeShowImage();
                return;
            }
        }
    }

    handleChange = (event, auction = true) => {
        const { name, value } = event.target;
        if (auction) {
            this.setState({
                auction: {
                    ...this.state.auction,
                    [name]: value
                }
            });
        }
        else {
            this.setState({
                ...this.state,
                [name]: value
            });
        }
    }

    onEditorStateChange = (description) => {
        this.setState({
            auction: {
                ...this.state.auction,
                description: description
            }
        });
    };

    onEditorStateChangeInfoAditional = (info_aditional) => {
        this.setState({
            auction: {
                ...this.state.auction,
                info_aditional: info_aditional
            }
        });
    };

    saveAuction = async (event) => {
        event.preventDefault();
        if (this.state.auction.auctionType === null) {
            toast.error(`Debe asignar el tipo de remate`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        if (this.state.auction.image === '') {
            toast.error(`Debe asignar una imagen`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        if (this.state.auction.dateTimeFrom === '' || this.state.auction.dateTimeTo === '') {
            toast.error(`Debe ingresar fecha de inicio y fecha de fin`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        if (this.state.auction.dateTimeFrom > this.state.auction.dateTimeTo) {
            toast.error(`La fecha de fin debe ser mayor a la de inicio`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        await this.setState({ loading: true });
        try {
            let data = {
                id: this.state.auction.id,
                token: this.props.sessionProps.account.token,
                description: stateToHTML(this.state.auction.description.getCurrentContent()),
                title: this.state.auction.title,
                date_from: this.state.auction.dateTimeFrom.format('YYYY-MM-DD HH:mm:ss'),
                date_to: this.state.auction.dateTimeTo.format('YYYY-MM-DD HH:mm:ss'),
                date_visibility: this.state.auction.dateTimeVisibility ? this.state.auction.dateTimeVisibility.format('YYYY-MM-DD HH:mm:ss') : null,
                image: this.state.auction.image,
                image_thumb: this.state.auction.image_thumb,
                visible: this.state.auction.visible.value,
                money: this.state.auction.money.value,
                auction_type: this.state.auction.auctionType.value,
                info_aditional: stateToHTML(this.state.auction.info_aditional.getCurrentContent()),
                catalog: this.state.auction.catalog !== '' ? this.state.auction.catalog : null
            };
            let response = null;
            if (this.state.auction.id === 0) {
                response = await auctionAdd(data);
            }
            else {
                response = await auctionUpdate(data);
            }
            toast(`La subasta ha sido ${this.state.auction.id === 0 ? 'creada' : 'modificada'} con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({
                loading: false,
                auction: {
                    ...this.state.auction,
                    id: response.data.data.auction !== undefined ? response.data.data.auction.id : response.data.data.id
                }
            });
            this.fetchAuction(this.state.auction.id)
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
        if (tab === 4) {
            this.fetchLogs();
        }
    }

    handleChangeLotTitle = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                if (value.length === 1) {
                    value = value.toUpperCase();
                }
                lot.title = value;
                lot.showButtonSaveTitle = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotQuantity = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.quantity = value !== '' && value < 1 ? 1 : value;
                lot.showButtonSaveQuantity = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotPriceBase = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.price_base = value;
                lot.showButtonSavePriceBase = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotDescription = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                if (value.length === 1) {
                    value = value.toUpperCase();
                }
                lot.description = value;
                lot.showButtonSaveDescription = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotRemitter = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.remitter = value;
                lot.showButtonSaveRemitter = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    saveLot = async (item, title, description, priceBase, quantity = null, images = null, remitter = null) => {
        await this.setState({ loading: true });
        try {
            let data = {
                id: item.id,
                token: this.props.sessionProps.account.token
            };
            if (title !== null) {
                data.title = title;
            }
            if (description !== null) {
                data.description = description;
            }
            if (priceBase !== null) {
                data.price_base = priceBase;
            }
            if (quantity !== null) {
                data.quantity = quantity;
            }
            if (images !== null) {
                data.images = images;
            }
            if (remitter !== null) {
                data.remitter = remitter;
            }
            let response = await edit(data);
            await this.setState({ loading: false });
            this.fetchLots();
            toast(`El lote ha sido modificado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    _sendPdfs = () => {
        confirmAlert({
            title: 'Enviar PDFs a compradores',
            message: `¿Esta seguro que desea enviar los PDFs a los compradores?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.sendPdfs()
                    }
                }
            ]
        });
    }

    sendPdfs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await sendPdfs({
                auctionId: this.state.auction.id,
                token: this.props.sessionProps.account.token
            });
            await this.setState({ loading: false });
            toast(`PDFs a compradores enviados con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            } else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    deleteLot = (item) => {
        confirmAlert({
            title: 'Eliminar lote ' + item.title,
            message: `¿Esta seguro que desea eliminar el lote?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteLot(item.id)
                    }
                }
            ]
        });
    }

    _deleteLot = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await deleteLot({
                token: this.props.sessionProps.account.token,
                id: id
            });
            await this.setState({ loading: false });
            this.fetchLots();
            toast(`Lote eliminado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            } else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    _downloadPdfs = () => {
        confirmAlert({
            title: 'Descargar PDFs de compradores',
            message: `¿Esta seguro que desea descargar los PDFs de los compradores?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.downloadPdfs()
                    }
                }
            ]
        });
    }

    downloadPdfs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await downloadPdfs({
                auctionId: this.state.auction.id,
                token: this.props.sessionProps.account.token
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    closeModal = async () => {
        await this.setState({
            lotSelect: null
        });
        this.fetchLots();
    }

    closeModalAddLot = async () => {
        await this.setState({
            showAddLotModal: false
        });
        this.fetchLots();
    }

    closeModalCreateLots = async () => {
        await this.setState({
            showModalCreateLots: false
        });
        this.fetchLots();
    }

    showAddLotModal = async () => {
        await this.setState({
            showAddLotModal: true
        });
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchLots();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchLots();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchLots();
    }

    openForceWin = async () => {
        for (let lot of this.state.lots) {
            if (parseInt(lot.id_lot) === parseInt(this.state.searchLot)) {
                await this.setState({
                    lotSelect: lot,
                    searchLot: ''
                });
                return;
            }
        }
        toast.error('No existe el número de lote ingresado, verifique.', {
            position: toast.POSITION.BOTTOM_CENTER,
            pauseOnHover: false,
            duration: 10000
        });
    }

    closeShowImage = async () => {
        await this.setState({
            imageShow: null,
            imageShowIdItem: null
        });
    }

    printData = () => {
        let link = document.createElement('a');
        let filterStatus = this.state.filterStatus.value !== 'all' ? '&filterStatus=' + this.state.filterStatus.value : '';
        link.href = `${Constants.BASE_URL}exportLots?id=${this.state.auction.id}&auth=${this.props.sessionProps.account.token}${filterStatus}`;
        link.download = `lotes_subasta_${this.state.auction.title}.xlsx`;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    changeFilterStatus = async (e) => {
        await this.setState({ filterStatus: e, page: 1 })
        this.fetchLots();
    }

    handleUpload = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = async function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            let wsname = readedData.SheetNames[0];
            let ws = readedData.Sheets[wsname];
            let lots = XLSX.utils.sheet_to_json(ws, { header: "A", defval: null, range: 1 });
            await this.setState({
                showModalCreateLots: true,
                lotsFromExcel: lots
            });
        }.bind(this);
        e.target.value = '';
        reader.readAsBinaryString(f)
    }

    createLots = async () => {
        await this.setState({ loading: true });
        let lots = [];
        this.state.lotsFromExcel.map(item => lots.push({
            title: item.A,
            description: item.B !== null ? item.B : '',
            price_base: item.D !== null ? item.D : 0,
            quantity: item.C !== null ? item.C : 1,
        }));
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                lots: lots,
                auction: this.state.auction.id
            };
            let response = await add(data);
            await this.setState({ loading: false });
            toast(`El lote ha sido creado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            this.closeModalCreateLots();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    {this.state.imageShow !== null && <ModalShowImage images={this.state.imageShow} deleteImage={(image) => this.deleteImage(image)}
                        close={() => this.closeShowImage()} lotId={this.state.imageShowIdItem} />}
                    {this.state.showAddLotModal && <ModalCreateLot close={() => this.closeModalAddLot()} auctionId={this.state.auction.id} />}
                    {this.state.showModalCreateLots && <ModalCreateLots close={() => this.closeModalCreateLots()} lots={this.state.lotsFromExcel}
                        createLots={() => this.createLots()} />}
                    {this.state.modalCropImage.length > 0 &&
                        this.state.modalCropImage.map((item, index) => item.show && <ModalCropImage imageSrc={item}
                            saveImage={(file, index) => this.uploadImage(file, index)} index={index} close={(index) => this.closeModalCrop(index)} />)}
                    <Container fluid>
                        <Breadcrumbs title="Remates" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.auction.id != '0' &&
                                            <React.Fragment>
                                                <h5>{this.state.auction.title}</h5>
                                                <Nav tabs className="nav-tabs-custom mb-4">
                                                    <NavItem>
                                                        <NavLink onClick={() => { this.toggleTab(1); }} className={`${this.state.activeTab === 1 && 'active'} font-weight-bold p-3`}>Información</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink onClick={() => { this.toggleTab(2); }} className={`${this.state.activeTab === 2 && 'active'} font-weight-bold p-3`}>Lotes</NavLink>
                                                    </NavItem>
                                                    {
                                                        this.state.auction.finished &&
                                                        <NavItem>
                                                            <NavLink onClick={() => { this.toggleTab(4); }} className={`${this.state.activeTab === 4 && 'active'} font-weight-bold p-3`}>Logs</NavLink>
                                                        </NavItem>
                                                    }
                                                </Nav>
                                            </React.Fragment>
                                        }
                                        <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <form onSubmit={this.saveAuction}>
                                                    <FormGroup row>
                                                        <Col md={2}>
                                                            <Label className="col-form-label">Tipo de remate</Label>
                                                        </Col>
                                                        <Col md={10}>
                                                            <Select
                                                                name="auctionType"
                                                                className="select2 select2-multiple"
                                                                placeholder="Seleccionar tipo de remate"
                                                                options={this.state.items}
                                                                value={this.state.auction.auctionType}
                                                                onChange={(sl) => this.handleSelectChange(sl, 'auctionType')}
                                                                required
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="col-md-2 col-form-label">Nombre</Label>
                                                        <Col md={10}>
                                                            <Input name="title" className="form-control" type="text" placeholder="Ingrese el nombre del remate"
                                                                value={this.state.auction.title} onChange={this.handleChange} required />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="col-md-2 col-form-label">
                                                            Fecha desde
                                                        </Label>
                                                        <Col md={10}>
                                                            <DatePicker
                                                                value={this.state.auction.dateTimeFrom} timeFormat="HH:mm"
                                                                onChange={val => this.setState({ auction: { ...this.state.auction, dateTimeFrom: val } })}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="col-md-2 col-form-label">
                                                            Fecha hasta
                                                        </Label>
                                                        <Col md={10}>
                                                            <DatePicker
                                                                value={this.state.auction.dateTimeTo} timeFormat="HH:mm"
                                                                onChange={val => this.setState({ auction: { ...this.state.auction, dateTimeTo: val } })}
                                                            />
                                                        </Col>
                                                    </FormGroup>

                                                    <React.Fragment>
                                                        <FormGroup row>
                                                            <Label htmlFor="example-datetime-local-input" className="col-md-2 col-form-label">Imagen</Label>
                                                            <Col md={10}>
                                                                <div className="custom-file">
                                                                    <CustomInput type="file" name="file" className="custom-file-input" onChange={this.handleFileChange} />
                                                                    <Label className="custom-file-label">Seleccionar imagen</Label>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                        {
                                                            this.state.auction.image !== '' &&
                                                            <FormGroup row>
                                                                <Col md={2} />
                                                                <Col md={10}>
                                                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                                <Col className="col-auto">
                                                                                    <img
                                                                                        onClick={() => this.setState({
                                                                                            imageShow: [{
                                                                                                image: `${this.state.auction.image}`,
                                                                                                image_thumb: `${this.state.auction.image}`
                                                                                            }],
                                                                                        })}
                                                                                        data-dz-thumbnail=""
                                                                                        height="80"
                                                                                        className="avatar-sm rounded bg-light"
                                                                                        alt={this.state.auction.image_name}
                                                                                        src={`${Constants.BASE_URL}${this.state.auction.image}`}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <a
                                                                                        href={`${Constants.BASE_URL}${this.state.auction.image}`}
                                                                                        className="text-muted font-weight-bold"
                                                                                        target="_blank"
                                                                                    >
                                                                                        {this.state.auction.image_name}
                                                                                    </a>
                                                                                    <p className="mb-0">
                                                                                        <strong>{this.state.auction.image_size} KB</strong>
                                                                                    </p>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            </FormGroup>
                                                        }
                                                        <FormGroup row>
                                                            <Label htmlFor="example-datetime-local-input" className="col-md-2 col-form-label">Catálogo</Label>
                                                            <Col md={10}>
                                                                <div className="custom-file">
                                                                    <CustomInput type="file" name="file" className="custom-file-input" onChange={this.handleCatalogChange} />
                                                                    <Label className="custom-file-label">Seleccionar catálogo</Label>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                        {
                                                            this.state.auction.catalog !== '' && this.state.auction.catalog !== null &&
                                                            <FormGroup row>
                                                                <Col md={2} />
                                                                <Col md={10}>
                                                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                                <Col className="col-auto">
                                                                                    <a href={`${Constants.BASE_URL}${this.state.auction.catalog}`} target="_blank">Ver catálogo actual</a>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            </FormGroup>
                                                        }
                                                        <FormGroup row>
                                                            <Col md={2}>
                                                                <Label className="col-form-label">Visibilidad</Label>
                                                            </Col>
                                                            <Col md={10}>
                                                                <Select
                                                                    name="visible"
                                                                    className="select2 select2-multiple"
                                                                    placeholder="Seleccionar visibilidad"
                                                                    options={[{ value: 0, label: 'No visible' }, { value: 1, label: 'Visible' }]}
                                                                    value={this.state.auction.visible}
                                                                    onChange={(sl) => this.handleSelectChange(sl, 'visible')}
                                                                    required
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label className="col-md-2 col-form-label">
                                                                Programar visibilidad
                                                            </Label>
                                                            <Col md={10}>
                                                                <DatePicker
                                                                    value={this.state.auction.dateTimeVisibility} timeFormat="HH:mm"
                                                                    onChange={val => this.setState({ auction: { ...this.state.auction, dateTimeVisibility: val } })}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                    <FormGroup row>
                                                        <Col md={2}>
                                                            <Label className="col-form-label">Moneda</Label>
                                                        </Col>
                                                        <Col md={10}>
                                                            <Select
                                                                name="money"
                                                                className="select2 select2-multiple"
                                                                placeholder="Seleccionar moneda"
                                                                options={this.state.moneys}
                                                                value={this.state.auction.money}
                                                                onChange={(sl) => this.handleSelectChange(sl, 'money')}
                                                                required
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <React.Fragment>
                                                        <FormGroup>
                                                            <Label>Descripción</Label>
                                                            <Editor
                                                                editorState={this.state.auction.description}
                                                                onEditorStateChange={this.onEditorStateChange}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                toolbar={{
                                                                    options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                                    inline: { inDropdown: true },
                                                                    link: { inDropdown: true },
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Información adicional</Label>
                                                            <Editor
                                                                editorState={this.state.auction.info_aditional}
                                                                onEditorStateChange={this.onEditorStateChangeInfoAditional}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                toolbar={{
                                                                    options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                                    inline: { inDropdown: true },
                                                                    link: { inDropdown: true },
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </React.Fragment>
                                                    <FormGroup className="mb-0 text-right">
                                                        <div>
                                                            <Button type="submit" color="primary" className="ml-2">
                                                                Guardar
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </form>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <Row>
                                                    {
                                                        this.state.auction.finished &&
                                                        <div className="ml-auto col-12 col-md-6 div-btn-add mobile-text-left" style={{ marginBottom: '1rem' }}>
                                                            <Link onClick={() => this._downloadPdfs()} className="btn btn-success btn-add w-xs">
                                                                <i className="mdi mdi-pdf-box"></i> Descargar PDFs
                                                            </Link>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.auction.finished &&
                                                        <div className="col-12 col-md-6 text-right div-btn-add margin-left-btn-add" style={{
                                                            marginBottom: '1rem', maxWidth: 'max-content'
                                                        }}>
                                                            <Link onClick={() => this._sendPdfs()} className="btn btn-success w-xs"
                                                                style={{ padding: '3px 1rem', marginLeft: 'auto' }}>
                                                                <i className="mdi mdi-pdf-box"></i> Enviar PDFs
                                                            </Link>
                                                        </div>
                                                    }
                                                </Row>
                                                <div className="row" style={{ marginBottom: '1rem' }}>
                                                    {
                                                        moment(this.state.auction.dateTimeTo).format('YYYY-MM-DD HH:mm:ss') >= moment().format('YYYY-MM-DD HH:mm:ss') &&
                                                        <div className="col-6" style={{ display: 'flex' }}>
                                                            <div style={{ display: 'grid' }}>
                                                                <label>Cargar lotes</label>
                                                                <input type="file" name="file" onChange={this.handleUpload} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className={`${moment(this.state.auction.dateTimeTo).format('YYYY-MM-DD HH:mm:ss') >= moment().format('YYYY-MM-DD HH:mm:ss') ? 'col-6' : 'col-12'}`}
                                                        style={{ display: 'flex' }}>
                                                        <FormGroup className="ml-auto mr-2" style={{ width: 'max-content' }}>
                                                            <Label>Ingresar comprador</Label>
                                                            <Input name="searchLot" type="text" className="form-control" value={this.state.searchLot}
                                                                onChange={(e) => this.handleChange(e, false)} placeholder="Número de lote"
                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.openForceWin() } }} />
                                                            <Button type="button" onClick={() => this.openForceWin()} className="mr-3 text-danger"
                                                                style={{
                                                                    position: 'absolute', padding: '0px', right: '1rem',
                                                                    top: '2.05rem', background: 'transparent', border: 'none'
                                                                }}>
                                                                <i className="mdi mdi-check-circle-outline font-size-22" style={{ color: 'green' }}></i>
                                                            </Button>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col-md-4 col-12 order-2 order-md-1 text-left div-btn-add">
                                                        <Select
                                                            name="orderBy"
                                                            className="select2 select2-multiple w-50"
                                                            options={[
                                                                { value: 'all', label: 'Mostrar todos' },
                                                                { value: Constants.STATUS_INITIAL, label: 'Pendiente' },
                                                                { value: Constants.STATUS_PENDING, label: 'Subastado' },
                                                                { value: Constants.STATUS_IN_PROGRESS, label: 'En proceso' },
                                                                { value: Constants.STATUS_NOT_AUCTIONED, label: 'No subastado' },
                                                                { value: Constants.STATUS_DELIVERED, label: 'Entregado' }
                                                            ]}
                                                            value={this.state.filterStatus}
                                                            onChange={(e) => this.changeFilterStatus(e)}
                                                        />
                                                    </div>
                                                    <div class="col-md-4 col-12 order-3 order-md-2 text-center">
                                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                                    </div>
                                                    <div class="col-md-4 col-12 order-1 order-md-3 text-right">
                                                        <Link onClick={() => this.printData()} className="btn btn-info btn-add w-xs"
                                                            style={{ marginBottom: '0.5rem', height: '35px', float: 'right' }}>
                                                            <i className="mdi mdi-printer"></i> Imprimir
                                                        </Link>
                                                        <Link onClick={() => this.showAddLotModal()} className="btn btn-success btn-add w-xs"
                                                            style={{ marginBottom: '0.5rem', marginRight: '0.5rem', height: '35px', float: 'right' }}>
                                                            <i className="mdi mdi-plus-thick"></i> Agregar
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">

                                                    <Table className="mb-0" style={{ width: "100%" }} id="printTable">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Lote</th>
                                                                <th>Nombre</th>
                                                                <th style={{ width: '10%' }}>Cantidad</th>
                                                                <th style={{ width: '10%' }}>Precio base</th>
                                                                <th>Descripción</th>
                                                                <th>Remitente</th>
                                                                <th>Comprador</th>
                                                                <th>Mejor oferta</th>
                                                                <th>Estado</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.lots.map(item =>
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                                item.images[0]?.image_thumb.includes(Constants.BASE_URL_IMAGES) ?
                                                                                    <img onClick={() => this.setState({ imageShow: item.images, imageShowIdItem: item.id })}
                                                                                        data-dz-thumbnail="" className="avatar-sm rounded bg-light"
                                                                                        src={`${item.images[0]?.image_thumb}`} style={{ width: '5rem', height: '5rem' }} />
                                                                                    :
                                                                                    <img onClick={() => this.setState({ imageShow: item.images, imageShowIdItem: item.id })}
                                                                                        data-dz-thumbnail="" className="avatar-sm rounded bg-light"
                                                                                        src={`${Constants.BASE_URL}${item.images[0]?.image_thumb}`} style={{ width: '5rem', height: '5rem' }} />

                                                                            }
                                                                            {
                                                                                !this.state.auction.finished &&
                                                                                <div style={{ position: 'relative' }}>
                                                                                    <Dropzone onDrop={acceptedFiles =>
                                                                                        this.handleFileChangeLot({ target: { files: acceptedFiles } })
                                                                                    }>
                                                                                        {({ getRootProps, getInputProps }) => (
                                                                                            <div className="dropzone"
                                                                                                style={{ minHeight: '0px', width: 'max-content', bottom: '0', position: 'absolute', right: '0', cursor: 'pointer' }}>
                                                                                                <div className="needsclick" {...getRootProps()}>
                                                                                                    <input {...getInputProps()} type="file" name="file" onChange={(e) => this.handleFileChangeLot(e, item)} />
                                                                                                    <i className="mdi mdi-upload"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </Dropzone>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        <th scope="row">{item.id_lot}</th>
                                                                        <td>
                                                                            <input name={item.id} value={item.title} onChange={this.handleChangeLotTitle}
                                                                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveLot(item, item.title, null, null) } }} />
                                                                            {
                                                                                item.showButtonSaveTitle &&
                                                                                <React.Fragment>
                                                                                    <Link to="#" onClick={() => this.saveLot(item, item.title, null, null)} className="text-success save-edit" id={`saveTitle`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                                                                </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <input name={item.id} value={item.quantity} onChange={this.handleChangeLotQuantity}
                                                                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveLot(item, null, null, null, item.quantity) } }} />
                                                                            {
                                                                                item.showButtonSaveQuantity &&
                                                                                <React.Fragment>
                                                                                    <Link to="#" onClick={() => this.saveLot(item, null, null, null, item.quantity)} className="text-success save-edit" id={`saveTitle`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                                                                </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        <td width="100px">
                                                                            <input type="number" name={item.id} value={item.price_base} onChange={this.handleChangeLotPriceBase}
                                                                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveLot(item, null, null, item.price_base) } }} />
                                                                            {
                                                                                item.showButtonSavePriceBase &&
                                                                                <React.Fragment>
                                                                                    <Link to="#" onClick={() => this.saveLot(item, null, null, item.price_base)} className="text-success save-edit" id={`savePriceBase`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                                                                </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <input name={item.id} value={item.description} onChange={this.handleChangeLotDescription}
                                                                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveLot(item, null, item.description, null) } }} />
                                                                            {
                                                                                item.showButtonSaveDescription &&
                                                                                <React.Fragment>
                                                                                    <Link to="#" onClick={() => this.saveLot(item, null, item.description, null)} className="text-success save-edit" id={`save`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                                                                </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {/*<input name={item.id} value={item.remitter} onChange={this.handleChangeLotRemitter}
                                                                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveLot(item, null, null, null, null, null, item.remitter) } }} />
                                                                            {
                                                                                item.showButtonSaveRemitter &&
                                                                                <React.Fragment>
                                                                                    <Link to="#" onClick={() => this.saveLot(item, null, null, null, null, null, item.remitter)} className="text-success save-edit" id={`save`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                                                                </React.Fragment>
                                                                            }*/}
                                                                            <Select
                                                                                options={this.state.customers.map(item => ({ value: item, label: item.name }))}
                                                                                className="select2 select2-multiple select-remitter"
                                                                                placeholder="Seleccionar remitente a buscar"
                                                                                value={item.remitter ? { value: item.remitter, label: item.remitter.name } : {}}
                                                                                onChange={(e) => this.saveLot(item, null, null, null, null, null, e.value.id_user)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {item.bestUserNameOffer}
                                                                            {
                                                                                item.status !== Constants.STATUS_DELIVERED && (item.status == Constants.STATUS_PENDING || item.status == Constants.STATUS_NOT_AUCTIONED) &&
                                                                                <React.Fragment>
                                                                                    <Link to="#" onClick={() => this.setState({ lotSelect: item })} className="text-success"><i className="mdi mdi-account-edit font-size-22" style={{ marginLeft: '5px' }}></i></Link>
                                                                                </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                                        </td>
                                                                        <td>
                                                                            {item.status == Constants.STATUS_INITIAL && <div className="badge badge-soft-warning font-size-12">Pendiente</div>}
                                                                            {item.status == Constants.STATUS_PENDING && <div className="badge badge-soft-info font-size-12">Subastado</div>}
                                                                            {item.status == Constants.STATUS_IN_PROGRESS && <div className="badge badge-soft-warning font-size-12">En proceso</div>}
                                                                            {item.status == Constants.STATUS_NOT_AUCTIONED && <div className="badge badge-soft-secondary font-size-12">No subastado</div>}
                                                                            {item.status == Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.status === Constants.STATUS_INITIAL ?
                                                                                    <Link onClick={() => this.deleteLot(item)} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                                    : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                    onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                                <div className="row">
                                                    <div className="col-12 text-right" style={{
                                                        alignItems: 'flex-end',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <h6>Subtotal:
                                                            {` ${this.state.auction.moneySymbol}`} {this.state.auction.subtotal}
                                                        </h6>
                                                        {
                                                            parseInt(this.state.auction.iva) > 0 ?
                                                                <h6>IVA:
                                                                    {` ${this.state.auction.moneySymbol}`} {this.state.auction.iva}
                                                                </h6> : null
                                                        }
                                                        <h6>Comisión:
                                                            {` ${this.state.auction.moneySymbol}`} {this.state.auction.comision}
                                                        </h6>
                                                        <h5>Total ingresado:
                                                            {` ${this.state.auction.moneySymbol}`} {this.state.auction.totalIngress}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={4}>
                                                <div className="table-responsive">
                                                    <Table className="mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Lote</th>
                                                                <th>Nombre</th>
                                                                <th>Cliente</th>
                                                                <th>Oferta</th>
                                                                <th>Fecha</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.auction.logs.map(item =>
                                                                    <tr style={{ color: `${item.is_active ? '#505d69' : 'red'}` }}>
                                                                        <th scope="row">{item.lot?.id_lot}</th>
                                                                        <td>{item.lot?.title}</td>
                                                                        <td>{item.user && item.user?.name}</td>
                                                                        <td>{item.money} {new Intl.NumberFormat("de-DE").format(item.offer)}</td>
                                                                        <td>{`${moment(item.datetime).format('dddd, D. MMMM HH:mm')} hs`}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {
                    this.state.lotSelect !== null &&
                    <ModalForceWin lot={this.state.lotSelect} money={this.state.auction.moneySymbol} close={this.closeModal}
                        isOnline={true} />
                }
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionsAddEdit);
