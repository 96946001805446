import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {

            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }

        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location !== undefined && this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">

                        <li className="menu-title">Inicio</li>
                        <li>
                            <Link to="/dashboard" className="waves-effect link-disabled">
                                <i className="ri-dashboard-line"></i>
                                <span className="ml-1">Dashboard</span>
                            </Link>
                        </li>

                        <li className="menu-title">Remates</li>
                        <li>
                            <Link to="/remates" className="waves-effect">
                                <i className="ri-auction-line"></i>
                                <span className="ml-1">Listado remates</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/lotes/entregar" className="waves-effect">
                                <i className="ri-check-line"></i>
                                <span className="ml-1">Entregar lotes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/remates/actual/lotes" className="waves-effect">
                                <i className="ri-list-ordered"></i>
                                <span className="ml-1">Remate actual lotes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/remates/actual/logs" className="waves-effect">
                                <i className="ri-file-list-line"></i>
                                <span className="ml-1">Remate actual logs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/remates/tipos" className="waves-effect">
                                <i className="ri-server-line"></i>
                                <span className="ml-1">Tipos de remates</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/remates/configs" className="waves-effect">
                                <i className="ri-settings-2-line"></i>
                                <span className="ml-1">Configuración</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/liquidar-remitentes" className="waves-effect">
                                <i className="ri-currency-fill"></i>
                                <span className="ml-1">Liquidación remitentes</span>
                            </Link>
                        </li>

                        <li className="menu-title">Clientes</li>
                        <li>
                            <Link to="/clientes" className="waves-effect">
                                <i className="ri-user-line"></i>
                                <span className="ml-1">Listado clientes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/clientes/frecuentes" className="waves-effect link-disabled">
                                <i className="ri-user-star-line"></i>
                                <span className="ml-1">Clientes frecuentes</span>
                            </Link>
                        </li>

                        <li className="menu-title">Banners</li>
                        <li>
                            <Link to="/banners" className="waves-effect">
                                <i className="ri-gallery-line"></i>
                                <span className="ml-1">Listado banners</span>
                            </Link>
                        </li>

                        <li className="menu-title">Páginas dinamicas</li>
                        {
                            this.props.configAccount.config?.include_page_buy ?
                                <li>
                                    <Link to="/pagina/1" className="waves-effect">
                                        <i className="ri-pages-line"></i>
                                        <span className="ml-1">Comprar</span>
                                    </Link>
                                </li> : ''
                        }
                        {
                            this.props.configAccount.config?.include_page_sell ?
                                <li>
                                    <Link to="/pagina/2" className="waves-effect">
                                        <i className="ri-pages-line"></i>
                                        <span className="ml-1">Vender</span>
                                    </Link>
                                </li> : ''
                        }
                        {
                            this.props.configAccount.config?.include_page_form_pay ?
                                <li>
                                    <Link to="/pagina/3" className="waves-effect">
                                        <i className="ri-pages-line"></i>
                                        <span className="ml-1">Forma de pago</span>
                                    </Link>
                                </li> : ''
                        }
                        {
                            this.props.configAccount.config?.include_page_faq ?
                                <li>
                                    <Link to="/pagina/4" className="waves-effect">
                                        <i className="ri-pages-line"></i>
                                        <span className="ml-1">FAQ</span>
                                    </Link>
                                </li> : ''
                        }
                        {
                            this.props.configAccount.config?.include_page_conditions ?
                                <li>
                                    <Link to="/pagina/5" className="waves-effect">
                                        <i className="ri-pages-line"></i>
                                        <span className="ml-1">Términos y condiciones</span>
                                    </Link>
                                </li>
                                : ''
                        }

                        <li className="menu-title">Cuenta</li>
                        <li>
                            <Link to="/cuenta/configuracion" className="waves-effect">
                                <i className="ri-settings-2-line"></i>
                                <span className="ml-1">Configuración</span>
                            </Link>
                        </li>

                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
})

export default connect(mapStateToProps, null)(SidebarContent);
